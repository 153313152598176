import _ from 'lodash';

const data = [
  {
    text: 'Kurs',
    value: 'COURSE',
  },
  {
    text: 'Vorstellung',
    value: 'PRESENTATION',
  },
  {
    text: 'Ort',
    value: 'PLACE',
  },
  {
    text: 'Anleitung',
    value: 'MANUAL',
  },
  {
    text: 'Ausstellung',
    value: 'EXHIBITION',
  },
  {
    text: 'Shop',
    value: 'SHOP',
  },
  {
    text: 'Treff',
    value: 'MEETING',
  },
  {
    text: 'Ereignis',
    value: 'EVENT',
  },
];

export default data;

export const getTextByValue = (value) => {
  const item = _.find(data, ['value', value]);
  return item.text;
};
