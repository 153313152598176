<template>
  <Categories :data="categories" :loading="loading" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    Categories: () => import(/* webpackChunkName: "category" */ '@/components/scoped/category/Table.vue'),
  },

  computed: {
    ...mapGetters({
      loading: 'category/loading',
      categories: 'category/all',
    }),
  },

  methods: {
    loadData() {
      this.$store.dispatch('category/loadAll');
    },
    destroyData() {
      this.$store.commit('category/resetAll');
    },
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.destroyData();
  },
};
</script>
