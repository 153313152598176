<template>
  <v-dialog v-model="show" max-width="600" @input="handleDialog">
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <v-form @submit.prevent="handleSubmit(onSubmit)">
          <v-toolbar dark>
            <v-toolbar-title>Kategorie {{ title }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <base-alert v-if="error && dialog">{{ error.message }}</base-alert>
            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
              <v-text-field label="Name" v-model="name.de" :error-messages="errors" />
            </ValidationProvider>
            <v-select label="Standard für Aktivitätstyp" v-model="defaultActivityType" :items="activityTypesItems" item-text="text" item-value="value" multiple v-if="!isSubcategory" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="onClose" :disabled="loading">Abbrechen</v-btn>
            <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">{{ button }}</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
      <v-dialog v-model="duplicateDefaultActivityType" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Doppelte Standard Aktivitätstypen</v-card-title>
          <v-card-text>
            <p>Eine oder mehrere Standard Aktivitätstypen sind bereits zugewiesen. Bitte entfernen Sie dieses zuerst.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDuplicateDefaultActivityType">Schliessen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ActivityTypes from '@/helpers/activityTypes';

const { mapFields } = createHelpers({
  getterType: 'category/field',
  mutationType: 'category/updateField',
});

export default {
  components: { ValidationObserver, ValidationProvider },

  data: () => ({
    activityTypesItems: ActivityTypes,
  }),

  computed: {
    ...mapGetters({
      dialog: 'category/dialog/form',
      category: 'category/get',
      subcategory: 'category/subcategory/get',
      isSubcategory: 'category/dialog/subcategory',
      duplicateDefaultActivityType: 'category/dialog/defaultActivityType',
      loading: 'loading/get',
      error: 'error/get',
    }),
    ...mapFields(['name', 'defaultActivityType']),
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    title() {
      if ((!this.isSubcategory && this.category) || (this.isSubcategory && this.subcategory)) {
        return 'bearbeiten';
      }
      return 'hinzufügen';
    },
    button() {
      if ((!this.isSubcategory && this.category) || (this.isSubcategory && this.subcategory)) {
        return 'Speichern';
      }
      return 'Hinzufügen';
    },
  },

  watch: {
    dialog: {
      handler(value, oldValue) {
        if (value === false && oldValue === true) {
          this.onClose();
        }
      },
    },
  },

  methods: {
    onClose() {
      this.$store.commit('category/dialog/toggleForm', false);
      this.resetForm();
    },
    onSubmit() {
      if (!this.isSubcategory && !this.category) {
        this.$store.dispatch('category/create', { name: this.name, defaultActivityType: this.defaultActivityType });
      } else if (!this.isSubcategory && this.category) {
        this.$store.dispatch('category/update', { name: this.name, defaultActivityType: this.defaultActivityType });
      } else if (this.isSubcategory && !this.subcategory) {
        this.$store.dispatch('category/subcategory/create', { name: this.name });
      } else if (this.isSubcategory && this.subcategory) {
        this.$store.dispatch('category/subcategory/update', { name: this.name });
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      if (this.isSubcategory) {
        this.$store.commit('category/subcategory/reset');
      } else if (this.category) {
        this.$store.commit('category/reset');
      }
      this.$store.commit('category/resetForm');
    },
    closeDuplicateDefaultActivityType() {
      this.$store.commit('category/dialog/toggleDefaultActivityType', false);
    },
    handleDialog(value) {
      if (value === false) {
        this.onClose();
      }
    },
  },
};
</script>
