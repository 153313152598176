<template>
  <div class="mt-3">
    <base-title>Kategorien</base-title>
    <v-card>
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on }">
          <v-btn color="#64A175" dark small absolute top right fab @click="createCategory" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Eine neue Kategorie hinzufügen</span>
      </v-tooltip>
      <v-data-table
        :headers="headers"
        :items="data"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [10, 20, -1] }"
        :items-per-page.sync="itemsPerPage"
        :mobile-breakpoint="0"
        virtual-rows
        ref="sortableTable"
      >
        <template v-slot:item="{ item }">
          <tr class="sortableRow" :key="item.id" :data-id="item.id">
            <td class="text-start">{{ item.name.de }}</td>
            <td class="text-start">
              <v-chip v-for="activityType in item.defaultActivityType" :key="`${item.id}-${activityType}`" v-html="getActivityTypesText(activityType)" />
            </td>
            <td class="text-right">
              <div class="text-no-wrap">
                <v-btn style="cursor: move" icon class="sortHandle">
                  <v-icon>mdi-drag-horizontal</v-icon>
                </v-btn>
                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="updateCategory(item)">
                      <v-list-item-title>Bearbeiten</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openSubcategories(item)">
                      <v-list-item-title>Unterkategorien</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteCategory(item)">
                      <v-list-item-title>Löschen</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <Form />
    <Delete />
    <Subcategories />
  </div>
</template>

<script>
import Sortable from 'sortablejs';
import { getTextByValue } from '@/helpers/activityTypes';

export default {
  components: {
    Form: () => import(/* webpackChunkName: "category" */ '@/components/scoped/category/Form.vue'),
    Delete: () => import(/* webpackChunkName: "category" */ '@/components/scoped/category/Delete.vue'),
    Subcategories: () => import(/* webpackChunkName: "category" */ '@/components/scoped/category/Subcategories.vue'),
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    headers: [
      {
        text: 'Name', value: 'name', filter: false, sortable: false,
      },
      {
        text: 'Standard für Aktivitätstyp', value: 'defaultActivityType', filter: false, sortable: false,
      },
      {
        value: 'action', align: 'right', filter: false, sortable: false,
      },
    ],
  }),

  computed: {
    itemsPerPage: {
      get() {
        return Number.parseInt(localStorage.getItem('table-category-items'), 10) || 10;
      },
      set(value) {
        localStorage.setItem('table-category-items', value);
      },
    },
  },

  methods: {
    createCategory() {
      this.$store.commit('category/dialog/toggleForm', true);
    },
    updateCategory(category) {
      this.$store.dispatch('category/load', category.id).then(() => {
        this.$store.commit('category/sync', { name: category.name, defaultActivityType: category.defaultActivityType });
        this.$store.commit('category/dialog/toggleForm', true);
      });
    },
    openSubcategories(category) {
      this.$store.dispatch('category/load', category.id).then(() => {
        // this.$store.dispatch('category/subcategory/loadAll');
        this.$store.commit('category/dialog/toggleSubcategory', true);
      });
    },
    deleteCategory(category) {
      this.$store.dispatch('category/load', category.id).then(() => {
        this.$store.commit('category/dialog/toggleDelete', true);
      });
    },
    dragReorder({ item }) {
      const ids = [];
      item.parentElement.querySelectorAll('[data-id]').forEach((el) => {
        ids.push(el.getAttribute('data-id'));
      });
      this.$store.dispatch('category/updateSorting', ids);
    },
    getActivityTypesText(value) {
      return getTextByValue(value);
    },
  },

  mounted() {
    /* eslint-disable no-new */
    const constainer = this.$refs.sortableTable.$el.querySelector('tbody');
    new Sortable(constainer, {
      draggable: '.sortableRow',
      handle: '.sortHandle',
      onEnd: this.dragReorder,
      onChoose: () => {
        constainer.classList.add('sortable-active');
      },
      onUnchoose: () => {
        constainer.classList.remove('sortable-active');
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.sortable-chosen {
  background: #eee !important;
}
.theme--light.v-data-table tbody.sortable-active tr:hover {
  background: #fff;
}
</style>
