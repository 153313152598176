<template>
  <v-dialog v-model="show" max-width="900" @input="handleDialog">
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>
          Unterkategorien
          <span v-if="category">{{ category.name.de }}</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table :headers="headers" :items="subcategories" :loading="loading" virtual-rows disable-pagination hide-default-footer ref="sortableTable">
        <template v-slot:header.action>
          <v-btn text small @click="onOpen">Neue Kategorie</v-btn>
        </template>
        <template v-slot:item="{ item }">
          <tr class="sortableRow" :key="item.id" :data-id="item.id">
            <td class="text-start">{{ item.name.de }}</td>
            <td class="text-right">
              <v-btn style="cursor: move" icon class="sortHandle">
                <v-icon>mdi-drag-horizontal</v-icon>
              </v-btn>
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="updateCategory(item)">
                    <v-list-item-title>Bearbeiten</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteCategory(item)">
                    <v-list-item-title>Löschen</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <!-- <template v-slot:header.action>
          <v-btn text small @click="onOpen">Neue Kategorie</v-btn>
        </template>
        <template v-slot:item.name="{ item }">{{ item.name.de }}</template>
        <template v-slot:item.action="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="updateCategory(item)">
                <v-list-item-title>Bearbeiten</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteCategory(item)">
                <v-list-item-title>Löschen</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>-->
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onClose">Schliessen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Sortable from 'sortablejs';

export default {
  data: () => ({
    headers: [
      {
        text: 'Name', value: 'name', filter: false, sortable: false,
      },
      {
        value: 'action', align: 'right', filter: false, sortable: false,
      },
    ],
  }),

  computed: {
    ...mapGetters({
      dialog: 'category/dialog/subcategory',
      category: 'category/get',
      subcategories: 'category/subcategory/all',
      loading: 'category/subcategory/loading',
    }),
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    dialog(value, oldValue) {
      if (oldValue && !value) {
        this.resetData();
      }
      if (!oldValue && value) {
        this.$store.dispatch('category/subcategory/loadAll').then(() => {
          this.initSorting();
        });
      }
    },
  },

  methods: {
    onClose() {
      this.$store.commit('category/dialog/toggleSubcategory', false);
    },
    onOpen() {
      this.$store.commit('category/dialog/toggleForm', true);
    },
    updateCategory(category) {
      this.$store.dispatch('category/subcategory/load', category.id).then(() => {
        this.$store.commit('category/sync', { name: category.name });
        this.$store.commit('category/dialog/toggleForm', true);
      });
    },
    deleteCategory(category) {
      this.$store.dispatch('category/subcategory/load', category.id).then(() => {
        this.$store.commit('category/dialog/toggleDelete', true);
      });
    },
    dragReorder({ item }) {
      const ids = [];
      item.parentElement.querySelectorAll('[data-id]').forEach((el) => {
        ids.push(el.getAttribute('data-id'));
      });
      this.$store.dispatch('category/subcategory/updateSorting', ids);
    },
    resetData() {
      this.$store.commit('category/subcategory/reset');
      this.$store.commit('category/subcategory/resetAll');
      this.$store.commit('category/reset');
    },
    initSorting() {
      /* eslint-disable no-new */
      const constainer = this.$refs.sortableTable.$el.querySelector('tbody');
      new Sortable(constainer, {
        draggable: '.sortableRow',
        handle: '.sortHandle',
        onEnd: this.dragReorder,
        onChoose: () => {
          constainer.classList.add('sortable-active');
        },
        onUnchoose: () => {
          constainer.classList.remove('sortable-active');
        },
      });
    },
    handleDialog(value) {
      if (value === false) {
        this.onClose();
        this.resetData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sortable-chosen {
  background: #eee !important;
}
.theme--light.v-data-table tbody.sortable-active tr:hover {
  background: #fff;
}
</style>
