<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card v-if="loadCategory">
      <base-alert v-if="error && dialog">{{ error.message }}</base-alert>
      <v-card-title class="headline">Kategorie löschen?</v-card-title>
      <v-card-text>
        <p>
          Möchten Sie die Kategorie
          <strong>«{{ loadCategory.name.de }}»</strong> wirklich löschen?
        </p>
        <p>Der Vorgang kann nicht rückgängig gemacht werden.</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onClose" :disabled="loading">Abbrechen</v-btn>
        <v-btn color="error" @click="onDelete" :loading="loading" :disabled="loading">Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      dialog: 'category/dialog/delete',
      category: 'category/get',
      subcategory: 'category/subcategory/get',
      loading: 'loading/get',
      error: 'error/get',
    }),
    loadCategory() {
      return this.subcategory || this.category;
    },
  },

  watch: {
    dialog(value, oldValue) {
      if (oldValue && !value) {
        this.resetData();
      }
    },
  },

  methods: {
    onClose() {
      this.$store.commit('category/dialog/toggleDelete', false);
    },
    onDelete() {
      if (this.subcategory) {
        this.$store.dispatch('category/subcategory/delete');
      } else {
        this.$store.dispatch('category/delete');
      }
    },
    resetData() {
      if (this.subcategory) {
        this.$store.commit('category/subcategory/reset');
      } else {
        this.$store.commit('category/reset');
      }
    },
  },

  destroyed() {
    if (this.dialog) {
      this.onClose();
      this.resetData();
    }
  },
};
</script>
